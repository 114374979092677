import { Actions, ActionTypes, State } from './types';
import { authServices } from '../auth';

export const initialState: State = {
  emptyCart: false,
  checkoutOverlay: false,
  orderLine: {
    loading: true,
  },
  reInsurance: {
    loading: true,
  },
  summary: {
    loading: true,
  },
  payButton: {},
  prohibitedItemsModal: {},
  soldItemsModal: {},
  addressForm: {},
  collectionPointModal: {},
  authModal: {
    // TODO: check hotmodule loading
    isOpen: authServices && !authServices.getSession(),
  },
  redirectToCheckoutError: null,
  refreshCartModal: {},
  pricingBreakdownModal: {
    isOpen: false,
  },
} as State;

export const reducer = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.SET_EMPTY_CART: {
      return {
        ...state,
        emptyCart: action.payload,
      };
    }
    case ActionTypes.SET_CHECKOUT_OVERLAY: {
      return {
        ...state,
        checkoutOverlay: action.payload,
      };
    }
    case ActionTypes.SET_ORDER_LINE_LOADING: {
      return {
        ...state,
        orderLine: {
          loading: action.payload,
        },
      };
    }
    case ActionTypes.SET_RE_INSURANCE_LOADING: {
      return {
        ...state,
        reInsurance: {
          loading: action.payload,
        },
      };
    }
    case ActionTypes.SET_SUMMARY_LOADING: {
      return {
        ...state,
        summary: {
          loading: action.payload,
        },
      };
    }
    case ActionTypes.SET_PAY_BUTTON_LOADING: {
      return {
        ...state,
        payButton: {
          loading: action.payload,
        },
      };
    }
    case ActionTypes.SOLD_ITEMS_MODAL: {
      return {
        ...state,
        soldItemsModal: { ...state.soldItemsModal, ...action.payload },
      };
    }
    case ActionTypes.PROHIBITED_ITEMS_MODAL: {
      return {
        ...state,
        prohibitedItemsModal: { ...state.prohibitedItemsModal, ...action.payload },
      };
    }
    case ActionTypes.SET_ADDRESS_FORM: {
      return {
        ...state,
        addressForm: action.payload,
      };
    }
    case ActionTypes.SET_COLLECTION_POINT_MODAL: {
      return {
        ...state,
        collectionPointModal: { ...state.collectionPointModal, ...action.payload },
      };
    }

    case ActionTypes.SET_AUTH_MODAL: {
      return {
        ...state,
        authModal: { ...state.authModal, ...action.payload },
      };
    }

    case ActionTypes.SET_REDIRECT_TO_CHECKOUT_ERROR: {
      return {
        ...state,
        redirectToCheckoutError: action.payload,
      };
    }

    case ActionTypes.SET_REFRESH_CART_MODAL: {
      return {
        ...state,
        refreshCartModal: action.payload,
      };
    }
    case ActionTypes.SET_MESSAGE_MODAL: {
      return {
        ...state,
        messageModal: action.payload,
      };
    }
    case ActionTypes.SET_PRICING_BREAKDOWN_MODAL: {
      return {
        ...state,
        pricingBreakdownModal: action.payload,
      };
    }
    case ActionTypes.SET_PAYMENT_METHOD_UPDATE_ALERT: {
      return {
        ...state,
        highlightPaymentMethodUpdate: action.payload,
      };
    }

    default:
      return state;
  }
};
