import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import './assets/scss/main.scss';
import classNames from 'classnames';
import Header from './components/Header/Header';
import Container from './components/Container/Container';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { BASENAME, ROUTES } from './helpers/routes';
import { cartActions, cartSelectors } from './features/cart';
import { addressActions } from './features/address';
import { authSelectors, authActions } from './features/auth';
import { uiActions, uiSelectors } from './features/ui';
import { i18nSelectors } from './features/i18n';
import { ErrorFallback } from './components/ErrorFallback';
import CheckoutFallback from './pages/Checkout/CheckoutFallback/CheckoutFallback';
import { orderLinesSelectors } from './features/order-lines';
import { bucketTestHooks } from './features/bucket-test';

const Auth = React.lazy(() => import('./components/Auth'));
const Checkout = React.lazy(() => import('./pages/Checkout/Checkout'));
const PaymentRedirect = React.lazy(() => import('./pages/PaymentRedirect/PaymentRedirect'));
const ErrorModals = React.lazy(() => import('./components/ErrorModals/ErrorModals/ErrorModals'));
const ReactToastify = React.lazy(() =>
  import('./components/ReactToastify').then((x) => ({ default: x.ReactToastify }))
);
const TrackingScripts = React.lazy(() => import('./components/TrackingScripts/TrackingScripts'));
const ScrollToTop = React.lazy(() => import('./components/ScrollToTop/ScrollToTop'));
const Footer = React.lazy(() => import('./components/Footer/Footer'));
const PricingBreakdownModal = React.lazy(() => import('./components/PricingBreakdownModal/PricingBreakdownModal'));

const App = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const authToken = useAppSelector(authSelectors.authTokenSelector);
  const disableActions = useAppSelector(uiSelectors.getCheckoutOverlay);
  const { language, currency, siteId } = useAppSelector(i18nSelectors.getRoot);
  const { isOpen: isAuthenticationModalOpen } = useAppSelector(uiSelectors.getAuthModal);
  const { isFeatureEnabled } = bucketTestHooks.useBucket();
  const onCloseAuthenticationModal = () => {
    dispatch(uiActions.setAuthModal({ isOpen: false }));
  };

  // getting authentication status from cookies.
  useEffect(() => {
    dispatch(authActions.get());
  }, [dispatch]);

  isFeatureEnabled((f) => Boolean(f.smoothCheckoutCollapsed), true);

  // fetching cart informations
  useEffect(() => {
    if (authToken) {
      dispatch(uiActions.setOrderLineLoading(true));
      dispatch(uiActions.setSummaryLoading(true));
      dispatch(authActions.getUser());
      dispatch(cartActions.fetch());
      dispatch(addressActions.fetch());
    } else {
      dispatch(uiActions.setOrderLineLoading(false));
      dispatch(uiActions.setSummaryLoading(false));
    }
  }, [dispatch, authToken, language, currency, siteId]);

  // Empty cart lock
  // Apply empty cart style even when the cart is loading when deleting the last item
  const isCartEmpty = useAppSelector(cartSelectors.getIsCartEmpty);
  const isLastProductBeingDeleted = useAppSelector(orderLinesSelectors.isLastProductBeingDeleted);
  const [lockEmptyCart, setLockEmptyCart] = React.useState(false);
  useEffect(() => {
    setLockEmptyCart(isLastProductBeingDeleted);
  }, [isLastProductBeingDeleted]);
  useEffect(() => {
    dispatch(uiActions.setEmptyCartUi(lockEmptyCart || isCartEmpty));
  }, [lockEmptyCart, isCartEmpty, dispatch]);

  return (
    <Router>
      <React.Suspense fallback>
        <TrackingScripts />
        <ReactToastify />
        <ScrollToTop />
      </React.Suspense>
      <div
        className={classNames('vc-checkout', { 'vc-checkout--overlay': disableActions })}
        data-testid={`testMainContainer${disableActions ? '_withOverlay' : ''}`}
      >
        <Header />
        <ErrorBoundary fallbackRender={ErrorFallback}>
          <Container>
            {isAuthenticationModalOpen && (
              <React.Suspense fallback>
                <Auth onCloseAuthenticationModal={onCloseAuthenticationModal} />
              </React.Suspense>
            )}
            <Switch>
              <Route path={ROUTES.PAYMENT_REDIRECT}>
                <React.Suspense fallback={<div data-testid="redirect-loading-fallback"></div>}>
                  <PaymentRedirect />
                </React.Suspense>
              </Route>
              <Route path={ROUTES.CHECKOUT}>
                <React.Suspense fallback={<CheckoutFallback />}>
                  <Checkout />
                </React.Suspense>
              </Route>
              <Route exact path={BASENAME}>
                <Redirect to={ROUTES.CHECKOUT} />
              </Route>
              <Route exact path="/">
                <Redirect to={ROUTES.CHECKOUT} />
              </Route>
              <Redirect to={ROUTES.CHECKOUT} />
            </Switch>
          </Container>
          <React.Suspense fallback>
            <Footer />
            <ErrorModals />
            <PricingBreakdownModal />
          </React.Suspense>
        </ErrorBoundary>
      </div>
    </Router>
  );
};

export default App;
