// hooks/usePaypalIntegration.ts
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import braintree, { Client } from 'braintree-web';
import type { PayPalCheckout } from 'braintree-web/paypal-checkout';
import { OrderLine } from '../../../types';
import { SubtotalsTypes } from '../../subtotals';
import { useAppDispatch } from '../../../app/hooks';
import { trackingHooks } from '../../tracking';
import { paymentActions, paymentServices, PaymentTypes } from '..';
import { AuthorizationData, AuthorizationResponse, ButtonSizeOption, LineItem } from 'paypal-checkout-components';
import logger from '../../../services/logger.service';
import { uiActions } from '../../ui';
import { cartActions } from '../../cart';

export interface PayPalButtons {
  render: (selector: string) => void;
}

export interface InitializePaypalResult {
  paypalCheckoutInstance: PayPalCheckout;
  renderPaypalButtons: (instance: PayPalCheckout) => PayPalButtons;
}

interface UsePaypalIntegrationProps {
  paymentToken: string;
  orderLines: OrderLine[];
  subtotals: SubtotalsTypes.State;
  cartId: string;
  onCheckCart: () => Promise<boolean>;
  prohibitedProductsInBasketRef: React.RefObject<string[]>;
}

export const usePaypalIntegration = ({
  paymentToken,
  orderLines,
  subtotals,
  cartId,
  onCheckCart,
  prohibitedProductsInBasketRef,
}: UsePaypalIntegrationProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { sendEvent } = trackingHooks.useAnalytics();
  const [isPaypalSdkLoading, setIsPaypalSdkLoading] = useState(false);

  const createPaypalOrder = useCallback(
    async (paypalCheckoutInstance: any) => {
      // checkcart first
      const isCartValid = await onCheckCart();
      if (!isCartValid) throw new Error('Cart validation failed');
      // proceed with creating order
      try {
        const paypalPayment = await paypalCheckoutInstance.createPayment({
          flow: 'checkout' as PaymentTypes.PaypalFlowType,
          amount: subtotals.totalAmount.cents / 100,
          currency: subtotals.totalAmount.currency,
          intent: 'capture' as PaymentTypes.PaypalItent,
          lineItems: paymentServices.getPaypalLineItems(orderLines, subtotals, t) as LineItem[],
        });
        return paypalPayment;
      } catch (error) {
        logger.error('payment::braintree::paypalButtonCreateOrder', error);
        throw error;
      }
    },
    [onCheckCart, orderLines, subtotals, t]
  );

  const handlePaypalApprove = useCallback(
    async (data: any, paypalCheckoutInstance: any): Promise<AuthorizationResponse> => {
      dispatch(uiActions.setPayButtonLoading(true));
      dispatch(uiActions.setCheckoutOverlay(true));

      try {
        const payload = await paypalCheckoutInstance.tokenizePayment(data);
        paymentServices.api
          .confirmPaypalPayment(paymentToken, payload.nonce)
          .then(() => {
            dispatch(
              paymentActions.resolvePayment({
                orderId: cartId,
                formattedAmount: subtotals.totalAmount.formatted,
              })
            );
          })
          .catch((error) => {
            logger.error('payment::confirmation::paypal', error);
            dispatch(uiActions.setPayButtonLoading(false));
            dispatch(paymentActions.rejectPayment({ message: error?.errors?.detail || null }));
          });
        return payload;
      } catch (error) {
        logger.error('payment::braintree::paypalErrorOnApprove', error);
        throw error;
      }
    },
    [cartId, dispatch, paymentToken, subtotals.totalAmount.formatted]
  );

  const renderPaypalButtons = useCallback(
    (paypalCheckoutInstance: PayPalCheckout) => {
      let paypalName = window.paypal.FUNDING.PAYPAL as unknown as string;

      if (!paypalName) {
        logger.error('payment::braintree::noPaypalName', 'missing_paypal_name_in_sdk_fallback_to_default');
        paypalName = 'paypal';
      }

      const paypalButtons = window.paypal.Buttons({
        fundingSource: paypalName,
        style: { size: 'medium' as ButtonSizeOption },
        // onClick order will be triggered simultaneously with createOrder
        onClick: async () => {
          if (window.DD_RUM?.addAction) {
            window.DD_RUM.addAction('clicked_on_pay', { paymentMethod: 'paypal' });
          }
          sendEvent('checkout_pay_button');
          window.processPaypal = 'PEND_PROCESS_ITEM';
        },
        // createOrder will be triggered simultaneously with onClick
        createOrder: () => createPaypalOrder(paypalCheckoutInstance),
        onApprove: (data: AuthorizationData) => handlePaypalApprove(data, paypalCheckoutInstance),
        onError: (error) => {
          logger.error('payment::braintree::paypalButtonOnError', error);
          if (prohibitedProductsInBasketRef.current?.length) {
            dispatch(cartActions.setProhibitedProductsInBasket(prohibitedProductsInBasketRef.current));
          } else {
            dispatch(paymentActions.rejectPayment({ message: null }));
          }
        },
        onCancel: (data) => {
          logger.error('payment::braintree::paypalCancel', data);
          dispatch(paymentActions.cancelPayment());
        },
      });

      return paypalButtons;
    },
    [createPaypalOrder, handlePaypalApprove, dispatch, sendEvent, prohibitedProductsInBasketRef]
  );

  const initializePaypal = useCallback(
    (braintreeInstance: Client): Promise<InitializePaypalResult> => {
      setIsPaypalSdkLoading(true);
      return new Promise((resolve, reject) => {
        braintree.paypalCheckout.create(
          {
            client: braintreeInstance,
          },
          (paypalCheckoutErr, paypalCheckoutInstance) => {
            if (paypalCheckoutErr) {
              logger.error('payment::braintree::paypalCheckoutErr', paypalCheckoutErr);
              reject(paypalCheckoutErr);
              return;
            }
            if (!paypalCheckoutInstance) {
              const error = new Error('no_instance');
              logger.error('payment::braintree::paypalCheckoutInstance', 'no_instance');
              reject(error);
              return;
            }

            paypalCheckoutInstance
              .loadPayPalSDK({
                currency: subtotals.totalAmount.currency,
                intent: 'capture',
              })
              .then(() => {
                setIsPaypalSdkLoading(false);
                resolve({ paypalCheckoutInstance, renderPaypalButtons });
              })
              .catch((error: any) => {
                logger.error('payment::braintree::loadPaypalSDK', error);
                reject(error);
              });
          }
        );
      });
    },
    [subtotals.totalAmount.currency, renderPaypalButtons]
  );

  return {
    isPaypalSdkLoading,
    initializePaypal,
  };
};
