import React, { useEffect, memo, useLayoutEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import './Sumup.scss';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { AddressTypes, addressSelectors } from '../../features/address';
import { subtotalsSelectors } from '../../features/subtotals';
import { uiActions, uiSelectors } from '../../features/ui';
import { cartSelectors, CartTypes } from '../../features/cart';
import Subtotals from '../Subtotals/Subtotals';
import PaymentButton from '../Payment/PaymentButton/PaymentButton';
import PaymentErrorPopin from '../Payment/PaymentErrorPopin/PaymentErrorPopin';
import SumupSkeleton from './SumupSkeleton/SumupSkeleton';
import Icon from '../Icons/Icons';

const Sumup: React.FC = () => {
  const { t } = useTranslation();
  const subtotals = useSelector(subtotalsSelectors.getRoot);
  const dispatch = useAppDispatch();
  const cartStatus = useAppSelector(cartSelectors.getStatus);
  const isBillingAddressMissing = cartStatus === CartTypes.StatusEnum.ERROR_REASON_NO_BILLING_ADDRESS;
  const addressStatus = useAppSelector(addressSelectors.getStatus);
  const [showStickyDetails, setShowStickyDetails] = useState(false);
  const isLoading = useAppSelector(uiSelectors.getSummaryLoading);
  const isCartEmpty = useAppSelector(uiSelectors.getIsEmptyCartUi);

  useEffect(() => {
    if (addressStatus === AddressTypes.StatusEnum.PEND_GET_LIST) {
      dispatch(uiActions.setSummaryLoading(true));
    }
  }, [dispatch, addressStatus]);

  useLayoutEffect(() => {
    if ([AddressTypes.StatusEnum.OK_GET_LIST, AddressTypes.StatusEnum.ERROR_GET_LIST].includes(addressStatus)) {
      dispatch(uiActions.setSummaryLoading(false));
    }
  }, [dispatch, addressStatus, cartStatus]);

  useEffect(() => {
    if (cartStatus === CartTypes.StatusEnum.PEND_GET_LIST) {
      dispatch(uiActions.setSummaryLoading(true));
    }
  }, [dispatch, cartStatus, addressStatus]);

  useEffect(() => {
    if (cartStatus !== CartTypes.StatusEnum.PEND_GET_LIST) {
      dispatch(uiActions.setSummaryLoading(false));
    }
  }, [dispatch, cartStatus]);

  /* Sticky part */
  const [isFixed, setIsFixed] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (container) {
          const intersectingFromBottom = entry.isIntersecting && entry.boundingClientRect.top > 0;
          const leavingFromTop = !entry.isIntersecting && entry.boundingClientRect.top < 0;
          setIsFixed(!(intersectingFromBottom || leavingFromTop));
        }
      },
      {
        root: null,
        rootMargin: `0px 0px 0px 0px`,
        threshold: 0,
      }
    );

    if (container) {
      observer.observe(container);
    }

    return () => {
      observer.disconnect();
    };
  }, [subtotals]);

  useEffect(() => {
    if (!isFixed) setShowStickyDetails(false);
  }, [isFixed]);

  if (isCartEmpty) return null;

  if (!subtotals.totalAmount) {
    return <SumupSkeleton />;
  }

  return (
    <div className={classNames('sumup__wrapper', { overlay: isFixed && showStickyDetails })}>
      <div className="sumup">
        <h2 className="sumup__title" tabIndex={-1} id="summaryAnchor">
          {t('SUMUP.TITLE', 'Order Summary')}
        </h2>
        <Subtotals />
        <div ref={containerRef}></div>
        <div className={`sumup__cta${isFixed ? ` sumup__cta--stickyMobile` : ''}`}>
          {isFixed && showStickyDetails && (
            <div>
              <button className="sumup__title" onClick={() => setShowStickyDetails(!showStickyDetails)}>
                {t('SUMUP.TITLE', 'Order Summary')}
                <Icon name="arrow-head-down"></Icon>
              </button>
              {showStickyDetails && (
                <div className="subtotals__content">
                  <Subtotals isSticky={true} />
                </div>
              )}
            </div>
          )}
          <div className="sumup__cta__total">
            {isFixed && !showStickyDetails ? (
              <button onClick={() => setShowStickyDetails(!showStickyDetails)}>
                {t('SUMUP.TITLE', 'Order Summary')} <Icon name="arrow-head-up"></Icon>
              </button>
            ) : !!subtotals.salesTaxAmount?.cents && isBillingAddressMissing ? (
              t('SUMMARY.TOTAL_ESTIMATED', 'Estimated total')
            ) : (
              t('SUMMARY.TOTAL', 'Total')
            )}{' '}
            <span className="subtotals__entry__amount" data-testid="summary_total_amount">
              {isLoading && <span className="ssc-line w-100"></span>}
              {!isLoading &&
                (isBillingAddressMissing
                  ? subtotals.totalAmountShoppingCart?.formatted
                  : subtotals.totalAmount?.formatted)}
            </span>
          </div>
          <PaymentButton />
        </div>
        <PaymentErrorPopin />
      </div>
    </div>
  );
};

export default memo(Sumup);
