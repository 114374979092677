import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { cartActions, cartSelectors, CartTypes } from '../../../features/cart';
import { paymentActions, paymentSelectors, PaymentTypes } from '../../../features/payment';
import { uiActions } from '../../../features/ui';
import { PayButton } from '../PayButton';

type NonGenericPaymentButtonProps = {
  gatewayInfo: string;
};
export const NonGenericPaymentButton = ({ gatewayInfo }: NonGenericPaymentButtonProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const checkCartStatus = useAppSelector(cartSelectors.getCheckCartStatus);
  const paymentStatus = useAppSelector(paymentSelectors.getPaymentStatus);

  useEffect(() => {
    if (checkCartStatus === CartTypes.CheckCartStatusEnum.OK_CHECK_LIST) {
      dispatch(paymentActions.payNonGenericRequest(gatewayInfo));
    }
  }, [dispatch, checkCartStatus, gatewayInfo]);

  useEffect(() => {
    if (paymentStatus === PaymentTypes.StatusEnums.RESET_PROCESS_ITEM) {
      dispatch(uiActions.setCheckoutOverlay(false));
    }
  }, [dispatch, paymentStatus]);

  return (
    <PayButton
      data-testid="payment_button_non_generic"
      onClick={() => {
        dispatch(uiActions.setCheckoutOverlay(true));
        dispatch(paymentActions.clickOnPay());
        dispatch(cartActions.checkRequest());
      }}
    >
      {t('PAYMENT.PAY_BUTTON', 'Submit order')}
    </PayButton>
  );
};
