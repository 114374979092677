import { CollectionPointsTypes } from '../collection-points';
import { CheckCartStatusEnum, StatusEnum } from './types';
import { State, Actions, ActionTypes } from './types';

export const initialState: State = { status: 'IDLE', errors: {}, check: { status: CheckCartStatusEnum.IDLE } } as State;

export const reducer = (state = initialState, action: Actions | CollectionPointsTypes.Actions): State => {
  switch (action.type) {
    case ActionTypes.FETCH: {
      return { ...state, status: StatusEnum.PEND_GET_LIST };
    }
    case ActionTypes.RESOLVE: {
      return {
        ...state,
        // get needs to stay pending untill saga actions for ActionTypes.SET
        // TO DO / TO LOOK AT :
        // is there a way to deal with our generic fetch/resolve/reject only ? without the ActionTypes.SET + sagas
        status: StatusEnum.PEND_GET_LIST,
      };
    }
    case ActionTypes.SET: {
      return {
        ...state,
        coupon: {},
        reduction: {} as State['reduction'],
        ...action.payload,
        status: StatusEnum.OK_GET_LIST,
      };
    }
    case ActionTypes.REJECT: {
      return {
        ...state,
        status: StatusEnum.ERROR_GET_LIST,
        errors: {
          ...state.errors,
        },
      };
    }
    case ActionTypes.REMOVE_LINE_ITEM: {
      const orderLines = state.orderLines.filter((x) => !action.payload.orderLineIds.includes(x));
      return { ...state, orderLines };
    }
    case ActionTypes.ADD_LINE_ITEM: {
      const orderLines = [...state.orderLines, action.payload];
      return { ...state, orderLines };
    }
    case ActionTypes.SET_NO_BILLING_ADDRESS: {
      if (action.payload) {
        return {
          ...state,
          status: StatusEnum.ERROR_REASON_NO_BILLING_ADDRESS,
          errors: {
            ...state.errors,
            billingAddress: true,
          },
        };
      }
      return {
        ...state,
        errors: {
          ...state.errors,
          billingAddress: false,
        },
      };
    }
    case ActionTypes.SET_DELIVERY_METHOD: {
      return {
        ...state,
        deliverySelectedMethod: action.payload,
      };
    }
    case ActionTypes.SET_SOLD_PRODUCTS_IN_BASKET: {
      if (!action.payload.length) {
        return {
          ...state,
          errors: {
            ...state.errors,
            soldItems: undefined,
          },
        };
      }
      return {
        ...state,
        status: StatusEnum.ERROR_REASON_SOLD_PRODUCTS_IN_BASKET,
        errors: {
          ...state.errors,
          soldItems: action.payload,
        },
      };
    }
    case ActionTypes.SET_PROHIBITED_PRODUCTS_IN_BASKET: {
      if (!action.payload?.length) {
        return {
          ...state,
          errors: {
            ...state.errors,
            prohibitedItems: undefined,
          },
        };
      }
      return {
        ...state,
        status: StatusEnum.ERROR_REASON_PROHIBITED_PRODUCTS_IN_BASKET,
        errors: {
          ...state.errors,
          prohibitedItems: action.payload,
        },
      };
    }
    case ActionTypes.CHECK_CART_REQUEST: {
      return {
        ...state,
        check: { status: CheckCartStatusEnum.PEND_CHECK_LIST },
      };
    }
    case ActionTypes.CHECK_CART_RESOLVE: {
      return {
        ...state,
        check: { status: CheckCartStatusEnum.OK_CHECK_LIST },
      };
    }
    // to do => test scenario where we have to reset the check status
    case ActionTypes.CHECK_CART_RESET: {
      return {
        ...state,
        check: { status: CheckCartStatusEnum.IDLE },
      };
    }
    case ActionTypes.CHECK_CART_REJECT: {
      return {
        ...state,
        check: { status: CheckCartStatusEnum.ERROR_CHECK_LIST },
      };
    }
    default:
      return state;
  }
};
