import { collectionPointsSelectors, collectionPointsActions, CollectionPointsTypes } from '../collection-points';
import { ForkEffect, takeLatest, select, CallEffect, call, PutEffect, SelectEffect, put } from 'redux-saga/effects';
import { addressSelectors } from '../address';
import { InferredAction, ActionTypes, Actions, DeliveryMethods, Response, CheckResponse } from './types';
import { api, transformOrder } from './services';
import { orderLinesActions } from '../order-lines';
import { SubtotalsTypes, subtotalsActions, subtotalsSelectors } from '../subtotals';
import {
  set,
  fetchResolve,
  fetchReject,
  checkReject,
  setProhibitedProductsInBasket,
  checkResolve,
  checkReset,
} from './actions';
import logger from '../../services/logger.service';
import toaster from '../../helpers/toaster';
import { AddressTypes } from '../address';
import { addressActions } from '../address';
import { orderLinesSelectors } from '../order-lines';
import { uiActions } from '../ui';
import { bucketTestSelectors } from '../bucket-test';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { setMessageModal } from '../ui/actions';

export function* fetch(): Generator<CallEffect<Response> | SelectEffect | PutEffect, Response | null, Response> {
  try {
    yield put(uiActions.setPayButtonLoading(true));
    const order: Response = yield call([api, 'getEntity']); // <- need to send the context otherwise `this` will be undefined
    yield put(fetchResolve(order));
    return order;
  } catch (e) {
    yield put(uiActions.setPayButtonLoading(false));
    yield put(fetchReject(`get ${JSON.stringify(e || {})}`));
    logger.error('cart::get', e);
  }
  return null;
}

export function* transformAndSave({
  payload: order,
}: InferredAction<ActionTypes.RESOLVE>): Generator<CallEffect | PutEffect | SelectEffect, void, AddressTypes.Entity> {
  try {
    // transforming order to new shape based on our requirements.
    const { orderLines, subTotals, cart } = transformOrder(order);
    yield put(orderLinesActions.set(orderLines));
    yield put(subtotalsActions.set(subTotals));
    yield put(set(cart));
    if (!cart.isElligibleToCollectionPoint) {
      yield put(collectionPointsActions.fetchListResolve([]));
    }
  } catch (err: any) {
    logger.error('cart::transform', err);
    toaster.error(`failed to map cart datas`);
  }
}

export function* setDeliveryMethod({
  payload,
}: InferredAction<ActionTypes.SET_DELIVERY_METHOD>): Generator<
  CallEffect | SelectEffect | PutEffect,
  void,
  AddressTypes.Entity | CollectionPointsTypes.Entity[]
> {
  try {
    yield call([api, 'setDeliveryMethod'], payload);
    if (payload === DeliveryMethods.HOME) {
      const lastSelectedAddressId: AddressTypes.Entity = (yield select(
        addressSelectors.getLastSelectedDelivery
      )) as AddressTypes.Entity;
      yield put(addressActions.setDeliveryMethodShipping(lastSelectedAddressId.addressId));
    } else {
      const collectionPoints: CollectionPointsTypes.Entity[] = (yield select(
        collectionPointsSelectors.getList
      )) as CollectionPointsTypes.Entity[];
      yield put(collectionPointsActions.setItemRequest({ reference: collectionPoints[0].id }));
    }
  } catch (e) {
    logger.error('cart::setDeliveryMethod', e);
  }
}

export function* checkSaga(): Generator<
  CallEffect | PutEffect | SelectEffect,
  void,
  CheckResponse | string[] | SubtotalsTypes.State | LDFlagSet
> {
  // WARNING IF YOU UPDATE THAT LOGIC YOU HAVE TO REPORT IT ON PAYPAL BUTTON
  try {
    const res = (yield call([api, 'check'])) as CheckResponse;
    const subtotals = (yield select(subtotalsSelectors.getRoot)) as SubtotalsTypes.State;
    const prohibitedProducts = res.prohibition?.filter((product) => product.prohibitedReason !== '') || [];
    const { checkCartUpdate } = (yield select(bucketTestSelectors.getFlags)) as LDFlagSet;

    // ensure there is no amount mismatch (e.g. cart was updated on another tab)
    if (checkCartUpdate && res.totalAmount && res.totalAmount?.cents !== subtotals.totalAmount.cents) {
      logger.error('cart::checkAmount', {
        orderId: 'test',
        clientAmount: subtotals.totalAmount.cents,
        serverAmount: res.totalAmount?.cents,
      });
      yield put(uiActions.setRefreshCartModal({ isOpen: true }));
    }
    // surprisingly if you get prohibited products it does not update res.check to false so it has to be checked first
    else if (prohibitedProducts.length > 0) {
      const prohibitedProductsIds = prohibitedProducts.map(({ productId }) => productId.toString());
      const prohibitedProductOrderlines = (yield select(
        orderLinesSelectors.getFromProductIds(prohibitedProductsIds)
      )) as string[];
      yield put(setProhibitedProductsInBasket(prohibitedProductOrderlines));
      yield put(uiActions.setCheckoutOverlay(false));
    } else if (res.needPassword) {
      yield put(uiActions.setCheckoutOverlay(false));
      // TO DO check the meaning of needPassword
      // yield call(clearSession);
      // yield put(setUIAuthModal({ isOpen: true }));
    } else if (res.check) {
      yield put(checkResolve());
    }
  } catch (e: any) {
    // in case you update this code please update the corresponding code on Paypal Payment Button
    // 409 will be returned in case of other errors
    if (e?.errors?.status === 409) {
      yield put(uiActions.setCheckoutOverlay(false));
      yield put(
        setMessageModal({
          isOpen: true,
          message: e.errors.detail,
        })
      );
      const errorType = e?.meta?.type || 'unknown-error-type';
      logger.error(`cart::checkCart::${errorType}`, e);
    } else {
      yield put(checkReject());
      logger.error('cart::checkCart::rejected', e);
    }
  }
}

export function* checkResetSaga() {
  yield put(checkReset());
}

export function* sagas(): Generator<ForkEffect<Actions>> {
  yield takeLatest(ActionTypes.FETCH, fetch);
  yield takeLatest(ActionTypes.RESOLVE, transformAndSave);
  yield takeLatest(ActionTypes.SET_DELIVERY_METHOD, setDeliveryMethod);
  yield takeLatest(ActionTypes.CHECK_CART_REQUEST, checkSaga);
  yield takeLatest(ActionTypes.CHECK_CART_RESOLVE, checkResetSaga);
}
