import type { RootState } from '../../store/index';
import { CheckoutPathResultCode } from '../payment/types';
import { AddressForm, MessageModal, Modal, PricingBreakdownModalType } from './types';

export const getIsEmptyCartUi = (state: RootState): boolean => state.ui.emptyCart;
export const getCheckoutOverlay = (state: RootState): boolean => state.ui.checkoutOverlay;
export const getOrderLineLoading = (state: RootState): boolean => state.ui.orderLine.loading;
export const getSummaryLoading = (state: RootState): boolean => state.ui.summary.loading;
export const getInsuranceLoading = (state: RootState): boolean => state.ui.reInsurance.loading;
export const getPayButtonLoading = (state: RootState): boolean => state.ui.payButton.loading;
export const getSoldItemsModal = (state: RootState): Partial<Modal> => state.ui.soldItemsModal;
export const getProhibitedItemsModal = (state: RootState): Partial<Modal> => state.ui.prohibitedItemsModal;
export const getAddressForm = (state: RootState): Partial<AddressForm> => state.ui.addressForm;
export const getCollectionPointsModal = (state: RootState): Partial<Modal> => state.ui.collectionPointModal;
export const getAuthModal = (state: RootState): Partial<Modal> => state.ui.authModal;
export const redirectionToCheckoutError = (state: RootState): CheckoutPathResultCode | null =>
  state.ui.redirectToCheckoutError;
export const getRefreshCartModal = (state: RootState): Partial<Modal> => state.ui.refreshCartModal;
export const getMessageModal = (state: RootState): Partial<MessageModal> => state.ui.messageModal;
export const getPricingBreakdwonModal = (state: RootState): PricingBreakdownModalType => state.ui.pricingBreakdownModal;
export const getHiglightPaymentMethodUpdate = (state: RootState): boolean => state.ui.highlightPaymentMethodUpdate;
